<template>
  <div class="score-n-responses row my-2 col-12 col-3xl-8">
    <div
    class="col-xs-6 col-sm-6 mb-4 mb-xxl-0"
    :class="{
      'col-lg-12': stacked,
      'col-lg-6': !stacked
    }"
    >
        <p class="text-muted fs-6 align-middle mb-2">
          Learner Quality Score
        </p>
        <div class="d-flex align-items-center">
          <p class="text-muted mb-0 fs-6 align-middle me-4"><i class="far fa-clipboard-check fa-fw me-1"></i>
            {{ learnerQualityScore }}
          </p>
          <p class="text-muted mb-0 fs-6 align-middle"><i class="far fa-user-check fa-fw me-1"></i>
            {{ courseParticipantsCount }}
          </p>
        </div>
    </div>
    <div
    class="col-xs-6 col-sm-6"
    :class="{
      'col-lg-12 col-xxl-12': stacked,
      'col-lg-6 col-xxl-6': !stacked
    }">
        <p class="text-muted fs-6 align-middle mb-2">
          Provider Average
        </p>
        <div class="d-flex align-items-center">
          <p class="text-muted mb-0 fs-6 align-middle me-4"><i class="far fa-clipboard-check fa-fw me-1"></i>
            {{ providerAverage }}
          </p>
          <p class="text-muted mb-0 fs-6 align-middle"><i class="far fa-user-check fa-fw me-1"></i>
            {{ providerParticipantsCount }}
          </p>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    course: {
      type: Object,
      required: false,
      default: null
    },
    stacked: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
    }
  },

  computed: {
    learnerQualityScore () {
      if (this.course.learner_quality_score !== null) {
        return this.course.learner_quality_score + ' / 10'
      }
      return '-'
    },

    courseParticipantsCount () {
      if (this.course.course_participant_count) {
        return this.course.course_participant_count
      }
      return '-'
    },

    providerAverage () {
      if (this.course.provider_avg_score !== undefined && this.course.provider_avg_score !== null) {
        return this.course.provider_avg_score + ' / 10'
      }
      return '-'
    },

    providerParticipantsCount () {
      if (this.course.provider_participant_count) {
        return this.course.provider_participant_count
      }
      return '-'
    }
  }
}
</script>
