<template>
  <fragment>
    <li v-if="course.duration">
      <span class="tag tag-secondary text-capitalize">{{ courseDurationFormatted }}</span>
    </li>
    <li v-if="course.start_date">
      <span class="tag tag-secondary">{{ startDate }}</span>
    </li>
    <li v-if="course.location">
        <span class="tag tag-primary">{{ course.location }}</span>
    </li>
    <li v-for="(accreditationValue, index) in course.cpd_values" :key="index" v-bind="accreditationValue">
      <span data-bs-toggle="tooltip" title="" class="tag tag-primary" data-bs-original-title="Educational Activity • Reviewing Performance • Measuring Outcomes">
        {{ accreditationValue.body_acronym ? accreditationValue.body_acronym : accreditationValue.name }}
        EA {{ accreditationValue.educational_activity | DisplayZeroIfNull }} • RP {{ accreditationValue.reviewing_performance | DisplayZeroIfNull }} • MO {{ accreditationValue.measuring_outcomes | DisplayZeroIfNull }}
      </span>
    </li>
    <li v-if="course.price == 0 || course.price == null">
      <span class="tag tag-primary">Free</span>
    </li>
    <li v-else>
      <span class="tag tag-primary">${{ course.price }} {{ course.currency }}</span>
    </li>
  </fragment>
</template>

<script>
export default {
  props: {
    course: Object
  },

  computed: {
    startDate () {
      if (this.course.type === 'on_demand') {
        return 'On-Demand'
      }
      return (new Date(this.course.start_date)).toLocaleDateString('en-AU')
    },

    courseDurationFormatted () {
      if (this.course.duration.formatted.includes('.0')) {
        return this.course.duration.formatted.replace('.0', '')
      }
      return this.course.duration.formatted
    }
  }
}
</script>
