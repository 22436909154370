<template>
  <span class="badge learning-plan-visibility" v-if="following">
    <i class="far fa-eye"></i> Following
  </span>
  <span class="badge learning-plan-visibility" v-else>
    <template v-if="visibility === 'private'">
      <i class="far fa-eye-slash"></i> Private
    </template>
    <template v-else-if="visibility === 'public'">
      <i class="far fa-eye"></i> Public
    </template>
    <template v-else-if="visibility === 'link'">
      <i class="far fa-link"></i> Link Access
    </template>
  </span>
</template>
<script>
export default {
  props: {
    following: Boolean,
    visibility: String
  }
}
</script>
