<template>
  <button
    type="button"
    class="btn btn-bookmark"
    :class="{ active }"
  >
    <i class="fad fa-bookmark"></i>
  </button>
</template>

<script>
export default {
  props: {
    course: Object
  },

  computed: {
    active () {
      return this.course?.user_learning_plans.length > 0
    }
  }
}
</script>
