<template>
  <article
    class="card card-course"
    :class="{
      'card-course-stacked': stacked,
      'card-course-horizontal': !stacked
    }"
  >
    <router-link
      :to="{ name: 'courses.show', params: { courseId: course.id } }"
      class="featured-container"
    >
      <img class="featured" :src="featuredImageUrl" width="1280" height="720" />
      <span class="featured-topic" v-if="course.featured_topic_name">
        {{ course.featured_topic_name }}
      </span>
    </router-link>

    <slot name="bookmark" />

    <span
      class="previous-marker"
      title="Past Event"
      v-c-tooltip
      v-if="course.previous"
    >
      <i class="far fa-history" />
    </span>

    <router-link
      :to="{ name: 'courses.show', params: { courseId: course.id } }"
      class="card-body"
    >

      <h2>{{ course.name }}</h2>

      <footer v-if="horizontalLogoUrl">
        <img class="provider-logo-horizontal" :src="horizontalLogoUrl" width="512" height="80" >
      </footer>

      <footer v-else>
        <p class="text-muted">{{ course.provider_name }}</p>
      </footer>

      <ul class="tag-list" v-if="!stacked">
        <course-tag :course="course"/>
      </ul>

      <learner-quality-score :course="course" :stacked="stacked"></learner-quality-score>

      <p>{{ course.short_description }}</p>
    </router-link>
  </article>
</template>

<script>
import { placeholders } from '@/utils/placeholders'
import CourseTag from '../CourseTag.vue'
import LearnerQualityScore from '../LearnerQualityScore.vue'

export default {
  components: {
    CourseTag,
    LearnerQualityScore
  },
  props: {
    course: Object,
    stacked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    featuredImageUrl () {
      return this.course.featured_image_url ?? placeholders.large
    },

    horizontalLogoUrl () {
      return this.course.provider_horizontal_logo_url ?? null
    },

    startDate () {
      if (this.course.type === 'on_demand') {
        return 'On-Demand'
      }
      return (new Date(this.course.start_date)).toLocaleDateString('en-AU')
    },

    price () {
      const price = parseFloat(this.course.price)
      if (price === 0 || this.course.price === null) {
        return 'Free'
      }

      return `$${price.toFixed(2)}`
    }
  },
}
</script>

<style>
  .cpd {
    width: 150px;
  }
</style>
