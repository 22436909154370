<template>
  <div
    class="modal modal-learning-plan"
    id="modal-learning-plan"
    tabindex="-1"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Add to Learning Plan</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-0">
          <ul class="list-data no-links">
            <li v-for="learningPlan in learningPlans" :key="learningPlan.id">
              <div class="row gx-2">
                <div class="col-sm d-flex align-items-center text-truncate">
                  <p class="text-truncate">{{ learningPlan.name }}</p>
                </div>
                <div
                  class="
                    col col-sm-auto col-course-count
                    d-flex
                    align-items-center
                    small
                    text-muted
                  "
                >
                  {{ learningPlan.courses_count }} Courses
                </div>
                <div
                  class="
                    col col-sm-auto col-visibility
                    d-flex
                    align-items-center
                  "
                >
                  <visibility-badge :visibility="learningPlan.visibility" :following="learningPlan.following"/>
                </div>
                <div class="col col-sm-auto col-btn">
                  <c-button
                    v-if="course.user_learning_plans.includes(learningPlan.id)"
                    :key="`remove-${learningPlan.id}`"
                    class="btn-sm"
                    :disabled="!learningPlan.creator"
                    @click="remove(learningPlan)"
                  >
                    Added
                  </c-button>
                  <c-button
                    v-else-if="learningPlan.creator"
                    :key="`add-${learningPlan.id}`"
                    class="btn-sm"
                    variant="outline-primary"
                    @click="add(learningPlan)"
                  >
                    Add
                  </c-button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer border-top">
          <validation-observer tag="form" class="d-flex w-100" @submit.prevent>
            <div class="row gx-2 flex-grow-1">
              <validation-provider tag="div" class="col" name="name" rules="required|max:255" mode="passive">
                <label class="visually-hidden">Create New Learning Plan</label>
                <c-input
                  placeholder="Create New Learning Plan"
                  v-model="newLearningPlanName"
                />
                <c-help-block/>
              </validation-provider>
              <div class="col-auto">
                <c-button validate type="submit" @click="createLearningPlan">Create</c-button>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { CModal } from '@chameleon/chameleon-vue'
import { cloneDeep } from 'lodash'
import VisibilityBadge from '../learning-plans/VisibilityBadge.vue'

export default {
  extends: CModal,
  name: 'AddLearningPlanModal',

  props: {
    courses: {
      type: Array,
      default: () => []
    }
  },

  components: {
    VisibilityBadge
  },

  data () {
    return {
      newLearningPlanName: null,
      course: {
        user_learning_plans: []
      }
    }
  },

  computed: {
    learningPlans: get('users/learningPlans/learningPlans')
  },

  mounted () {
    this.addEventListener('open', async () => {
      await this.setUser(this.$user)
      await this.getLearningPlans({ fresh: true })
    })
  },

  beforeDestroy () {
    this.resetLearningPlans()
  },

  methods: {
    setUser: call('users/setUser'),
    getLearningPlans: call('users/learningPlans/getLearningPlans'),
    storeLearningPlan: call('users/learningPlans/storeLearningPlan'),
    resetLearningPlans: call('users/learningPlans/resetLearningPlans'),
    updateCoursesCount: call('users/learningPlans/updateCoursesCount'),
    storeLearningPlanCourse: call('learningPlans/courses/storeLearningPlanCourse'),
    deleteLearningPlanCourse: call('learningPlans/courses/deleteLearningPlanCourse'),

    async createLearningPlan () {
      const learningPlan = await this.storeLearningPlan({ name: this.newLearningPlanName, course_id: this.course.id })

      this.newLearningPlanName = null

      this.course.user_learning_plans.push(learningPlan.id)
      this.update()
    },

    async add (learningPlan) {
      await this.storeLearningPlanCourse({ id: learningPlan.id, courseId: this.course.id })

      this.updateCoursesCount({ id: learningPlan.id, count: learningPlan.courses_count + 1 })
      this.course.user_learning_plans.push(learningPlan.id)

      this.update()
    },

    async remove (learningPlan) {
      await this.deleteLearningPlanCourse({ id: learningPlan.id, courseId: this.course.id })

      this.updateCoursesCount({ id: learningPlan.id, count: learningPlan.courses_count - 1 })
      this.course.user_learning_plans.splice(this.course.user_learning_plans.indexOf(learningPlan.id), 1)

      this.update()
    },

    update () {
      const courses = [...this.courses]
      const index = courses.findIndex(({ id }) => id === this.course.id)

      courses.splice(index, 1, cloneDeep(this.course))

      this.$emit('updated', courses)
    }
  }
}
</script>
